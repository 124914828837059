<template>
  <div>
    <TopNavBar/>

    <b-container>
      <b-row align-h="center">
        <b-col class="mt-5 col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card
            :title="$t('Login')"
            class="mt-5"
          >
            <b-form @submit.prevent="submit">
              <b-form-group
                :label="$t('Username:')"
                id="username"
                label-for="username"
              >
                <b-form-input
                  :placeholder="$t('Enter username')"
                  id="username"
                  type="text"
                  v-model="username"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                :label="$t('Password:')"
                id="password"
                label-for="password"
              >
                <b-form-input
                  :placeholder="$t('Enter password here')"
                  id="password"
                  type="password"
                  v-model="password"
                ></b-form-input>
              </b-form-group>

<!--              <b-form-group id="remember_me">-->
<!--                <b-form-checkbox class="small" v-model="remember_me" value="">{{$t('Remember me')}}</b-form-checkbox>-->
<!--              </b-form-group>-->

              <div v-if="error">
                <b-alert show="" variant="danger">
                  {{error}}
                </b-alert>
              </div>
              <vue-recaptcha
                :sitekey="this.recaptchaSiteKey"
                @expired="onCaptchaExpired"
                @verify="onCaptchaVerified"
                ref="recaptcha"
                size="invisible"
              ></vue-recaptcha>

              <b-button type="submit" variant="primary">{{$t('Login')}}</b-button>
            </b-form>

            <router-link :to="{name: 'auth.password_reset_email'}" class="small">{{$t('Forgot password?')}}
            </router-link>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { AuthService } from '../../services/auth.service'
import VueRecaptcha from 'vue-recaptcha'
import { API_URL, RECAPTCHA_SITE_KEY } from '../../env'
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      remember_me: false,
      error: '',
      recaptchaSiteKey: RECAPTCHA_SITE_KEY
    }
  },
  methods: {
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    },
    submit: function () {
      this.$refs.recaptcha.execute()
    },
    onCaptchaVerified (recaptchaToken) {
      const token = recaptchaToken
      this.$refs.recaptcha.reset()

      axios.post(`${API_URL}/auth/recapcha/`, { token: token })
        .then(response => {
          if (response.data.status) {
            AuthService.makeLogin({ username: this.username, password: this.password })
              .then((response) => {
                this.$router.push({ name: 'home' })
                this.error = ''
              })
              .catch((error) => {
                if (!error) {
                  this.error = 'Username or password mismatch'
                } else if (error.message.username) {
                  this.error = this.$t('Username:') + ' ' + this.$t(error.message.username)
                } else if (error.message.password) {
                  this.error = this.$t('Password:') + ' ' + this.$t(error.message.password)
                } else if (error.message.non_field_errors) {
                  this.error = this.$t('Username:') + '/' + this.$t('Password:') + ' ' + this.$t(error.message.non_field_errors)
                } else {
                  this.error = error.message
                }
              })
          }
        })
    }
  },
  components: { TopNavBar, VueRecaptcha }
}
</script>

<style scoped>

</style>
